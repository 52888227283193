export class ToCSV {
    #data;
    #separator;
    #file;
    #autoDownload;
    constructor(data, separator=",", autoDownload = true) {
        this.#data = data;
        this.#separator = separator;
        this.#autoDownload = autoDownload;
        this.#file = null;
    }

    #tableToCSVText(data){
        let csvText = "";
        if (data.length === 0)
            return csvText;
        csvText = Object.keys(data[0]).toString().replaceAll(",", this.#separator) + "\n";
        let gobalThis = this;
        data.forEach(function (item){
            csvText += Object.values(item).toString().replaceAll(",", gobalThis.#separator) + "\n";
        });
        return csvText;
    }

    downloadFile(href){
        let link = document.createElement("a");
        link.href = href;
        link.style.display = 'block';
        link.style.visibility = 'hidden';
        link.click();
        link.remove();
    }

    createCSVFile(){
        const csvText = this.#tableToCSVText(this.#data);
        let data = new Blob([csvText], {type: 'text/csv'});
        if (this.#file !== null) {
            window.URL.revokeObjectURL(this.#file);
        }
        this.#file = window.URL.createObjectURL(data);
        if (this.#autoDownload)
            this.downloadFile(this.#file);
        return this.#file;
    }
}